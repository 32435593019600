import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import ContactIcon from '@mui/icons-material/PhoneOutlined';
import LogOutIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CircuitIcon from '@mui/icons-material/AccountTreeOutlined';
import MaintenanceIcon from '@mui/icons-material/SecurityOutlined';
import ReportIcon from '@mui/icons-material/MailOutlined';
import DashboardIcon from '@mui/icons-material/PollOutlined';
import SwitchClientIcon from '@mui/icons-material/ShuffleOutlined';
import FacilityIcon from '@mui/icons-material/BusinessOutlined';
import AlarmsIcon from '@mui/icons-material/NotificationsActiveOutlined';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import IntegrationsIcon from '@mui/icons-material/ExtensionOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import NotesIcon from '@mui/icons-material/BorderColorOutlined';
import PestControlRodentIcon from '@mui/icons-material/PestControlRodentOutlined';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLockOutlined';
import ForcedOnIcon from '@mui/icons-material/OfflineBoltOutlined';
import FreezeLogsIcon from '@mui/icons-material/AcUnitOutlined';
import ReplaceDeviceIcon from '@mui/icons-material/AutoFixHigh';
import NotificationIcon from '@mui/icons-material/AssignmentOutlined';
import UpgradePathIcon from '@mui/icons-material/ForkRight';
import CategoryIcon from '@mui/icons-material/Category';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import RulesIcon from '@mui/icons-material/TuneOutlined';
import ScheduleIcon from '@mui/icons-material/ScheduleOutlined';
import SupportIcon from '@mui/icons-material/Support';
import EhtGroupLogo from '@mui/icons-material/FilterHdrOutlined';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOffOutlined';
import ConfigureSearchIcon from '@mui/icons-material/ManageSearchOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Divider from "@mui/material/Divider";
import { Map } from 'immutable';
import { useLocation } from 'react-router-dom';
import NavBarItem from 'src/component/UI/NavBar/NavBarItem';
import SubNavBarItem from 'src/component/UI/NavBar/SubNavBarItem';
import PermissionFence from 'src/component/PermissionFence';
import ChangeClientModal from 'src/component/UI/ChangeClientModal';
import { getClientsForUser, getClientId, getAuthenticatedUser, getClient, getPluralWorkTicketLabel, getTrainingURL, getTrainingPassword } from 'src/module/authentication/selector';
import { changeClient } from 'src/module/authentication/action';
import { updateUser } from 'src/module/user/action';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MenuIcon from '@mui/icons-material/Menu';
import { Popover } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavBarCatagoryItem from 'src/component/UI/NavBar/NavBarCatagoryItem';
import { CompareArrows, ExpandMore } from '@mui/icons-material';
import variables from 'src/style/variable/variables.module.scss';
import DifferenceIcon from '@mui/icons-material/Difference';
import FeatureFence from 'src/component/FeatureFence';
import AtcomLogo from 'src/component/UI/AtcomLogo';
import { isWhitelabel } from 'src/utils/utils';
import SummarizeIcon from '@mui/icons-material/Summarize';
import smarttrace from 'src/assets/smarttrace.svg';

export default function SideNavBar (props) {
  const dispatch: any = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openAlarmsCategory, setOpenAlarmsCategory] = useState(false);
  const [openDevicesCategory, setOpenDevicesCategory] = useState(false);
  const [openSettingsPopOver, setOpenSettingsPopOver] = useState(false);
  const [changeFacilityPopOver, setChangeFacilityPopOver] = useState(false);
  const [anchorElForFacility, setAnchorElForFacility] = useState<HTMLDivElement | null>(null);

  const clients: any = useSelector(getClientsForUser);
  const user = useSelector(getAuthenticatedUser);
  const pluralWorkTicketLabel = useSelector(getPluralWorkTicketLabel);
  const currentClientId: any = useSelector(getClientId);
  const client = useSelector(getClient);

  const clientName = client?.get('name');
  const whitelabel = isWhitelabel();

  const [open, setOpen] = useState(user.getIn(['preferences', 'navbarOpen'], true));
  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);

  const trainingURL = useSelector(getTrainingURL);
  const trainingPassword = useSelector(getTrainingPassword);

  const className = open ? "bigNav" : "smallNav";

  const closeAllCategories = () => {
    setOpenAlarmsCategory(false);
    setOpenDevicesCategory(false);
  };
  const handleClickOnAlarmsCategory = () => {
    closeAllCategories();
    setOpenAlarmsCategory(!openAlarmsCategory);
  };
  const handleClickOnDevicesCategory = () => {
    closeAllCategories();
    setOpenDevicesCategory(!openDevicesCategory);
  };
  const handleClickOnHeader = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettingsPopOver(!openSettingsPopOver);
  };

  //  purpose: returns true if any of the sub categories links are open
  //  how: checks if the path exists in the array of string (routes)
  const isCategoryActive = (routes: Array<string>) => {
    const location = useLocation();
    return routes.includes(location.pathname.replace("/", "")) || routes.includes(location.pathname.split('/')[1]);
  };

  const toggleDrawer = () => {
    dispatch(updateUser(null, Map({ navbarOpen: !open })));

    setOpen(!open);
    if (open) {
      closeAllCategories();
    }
    props.onToggleDrawer(!open);
  };

  const handleChangeClient = (clientId) => {
    handleCloseSettingsPopOver();
    dispatch(updateUser(null, Map({ defaultFacility: clientId })));
    dispatch(changeClient(clientId));
    if (isMobile) {
      toggleDrawer();
    }
  };


  const handleCloseSettingsPopOver = () => {
    setAnchorEl(null);
    setOpenSettingsPopOver(!openSettingsPopOver);
  };
  const handleClickOnChangeFacility = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElForFacility(changeFacilityPopOver ? null : event.currentTarget);
    setChangeFacilityPopOver(!changeFacilityPopOver);
  };
  const handleCloseChangeFacility = () => {
    setAnchorElForFacility(null);
    setChangeFacilityPopOver(!changeFacilityPopOver);
  };
  const handleMobileDrawerItemClick = () => {
    if (!isMobile) {
      return;
    }

    toggleDrawer();
  };

  function menuHeader () {

    const whiteListLogo = <AtcomLogo size={24} color='#fff' square />;
    const whiteListLabelHTML = <AtcomLogo size={90} color='#fff' textOnly />;

    const regLogo = <EhtGroupLogo style={{ fontSize: 32 }} />;
    const regName = <><span style={{ marginLeft: '-10px' }}><img src={smarttrace} width={'150px'} /> </span><span className="chevron" style={{ lineHeight: '40px' }}><ExpandMore /></span></>;

    if (whitelabel) {
      return <NavBarItem onClick={handleClickOnHeader} className={classNames('main', 'whitelabel')} icon={whiteListLogo} label={whiteListLabelHTML} />;
    } else {
      return <NavBarItem onClick={handleClickOnHeader} className='main' icon={regLogo} label={regName} />;
    }
  }


  return (
    <>
      <div id='navbar-header' data-testid='navbar-header'>
        {menuHeader()}
      </div>
      <div id='navbar-upper' data-testid='navbar-upper'>
        <div onClick={handleMobileDrawerItemClick}>
          <NavBarItem to='/' className={className} icon={<DashboardIcon style={{ fontSize: 24 }} />} label='Dashboard' />
        </div>
        <NavBarCatagoryItem
          isCategoryOpen={openAlarmsCategory}
          isNavBarOpen={isMobile ? true : open}
          triggerCategory={handleClickOnAlarmsCategory}
          label="Alarms"
          options={<div onClick={handleMobileDrawerItemClick}>
            <PermissionFence can='read-eht-alarm'>
              <NavBarItem to='/alarms/active' label='Active Alarms' icon={<AlarmsIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <PermissionFence can='read-eht-alarm'>
              <NavBarItem to='/alarms/history' label='Alarm Historian' icon={<HistoryIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <NavBarItem to='/maintenance' label='Validate' icon={<MaintenanceIcon style={{ fontSize: 24 }} />} />
          </div>}
          header={<PermissionFence can='read-eht-alarm'>
            <NavBarItem to='/alarms/active' disabled className={className} isActive={isCategoryActive(["alarms", "historian", "maintenance"])} isCategoryOpen={openAlarmsCategory} isCategory icon={<AlarmsIcon style={{ fontSize: 24 }} />} label='Alarms' />
          </PermissionFence>}
        />

        <NavBarCatagoryItem
          isCategoryOpen={openDevicesCategory}
          isNavBarOpen={isMobile ? true : open}
          triggerCategory={handleClickOnDevicesCategory}
          label="Devices"
          options={<div onClick={handleMobileDrawerItemClick}>
            <PermissionFence can='read-device'>
              <NavBarItem to='/devices/list' label='Device List' icon={<CircuitIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <PermissionFence can='read-device'>
              <NavBarItem to='/devices/comm-loops' label='Comm Loops' icon={<PortableWifiOffIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <PermissionFence can='read-batch-schedule'>
              <FeatureFence can={['batches-and-recipes', 'program-device']}
                noPermissionComponent={<NavBarItem to='/program/list' locked label='Mass Program' icon={<SettingsSuggestIcon style={{ fontSize: 24 }} />} />}
              >
                <NavBarItem to='/program/list' label='Mass Program' icon={<SettingsSuggestIcon style={{ fontSize: 24 }} />} />
              </FeatureFence>
            </PermissionFence>
            <PermissionFence can='edit-device'>
              <FeatureFence can={['replace-device', 'program-device']}
                noPermissionComponent={<NavBarItem to='/devices/upgrade' label='Replace Device' locked icon={<ReplaceDeviceIcon style={{ fontSize: 24 }} />} />}>
                <NavBarItem to='/devices/upgrade' label='Replace Device' icon={<ReplaceDeviceIcon style={{ fontSize: 24 }} />} />
              </FeatureFence>
            </PermissionFence>
            <PermissionFence can='read-device'>
              <NavBarItem to='/devices/programming-audit' label='Program Audit' icon={<DifferenceIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <PermissionFence can='read-eht-note'>
              <NavBarItem to='/notes/note' label='Notes' icon={<NotesIcon style={{ fontSize: 24 }} />} />
            </PermissionFence>
            <PermissionFence can='read-device'>
              <NavBarItem to='/devices/groups' label='Groups' icon={<CategoryIcon style={{ fontSize: 25 }} />} />
            </PermissionFence>
            <PermissionFence can='read-eht-note'>
              <NavBarItem to='/devices/reports' label='Reports' icon={<SummarizeIcon style={{ fontSize: 25 }} />} />
            </PermissionFence>
            <PermissionFence can='edit-device'>
              <NavBarItem to='/devices/send-modbus-request' label='Modbus Requests' icon={<CompareArrows style={{ fontSize: 24 }} />} />
            </PermissionFence>
          </div>}
          header={
            <PermissionFence can='read-device'>
              <NavBarItem to='/devices/list' disabled className={className} isActive={isCategoryActive(["devices", "notes/note", "program/list"])} isCategoryOpen={openDevicesCategory} isCategory icon={<CircuitIcon style={{ fontSize: 24 }} />} label='Devices' />
            </PermissionFence>
          }
        />
        <div onClick={handleMobileDrawerItemClick}>
          <PermissionFence can='read-eht-note'>
            <NavBarItem to='/notes/loto' className={className} icon={<PhonelinkLockIcon style={{ fontSize: 20 }} />} label='LOTO' />
          </PermissionFence>

          <PermissionFence can='read-eht-note'>
            <NavBarItem to='/notes/nuisance' className={className} icon={<PestControlRodentIcon style={{ fontSize: 24 }} />} label='Nuisance' />
          </PermissionFence>

          <PermissionFence can='read-forced-on-circuit'>
            <FeatureFence can={['forced-on-logs']}>
              <NavBarItem to='/forced-on' className={className} icon={<ForcedOnIcon style={{ fontSize: 24 }} />} label='Forced On Circuits' />
            </FeatureFence>
          </PermissionFence>

          <PermissionFence can='read-freeze-log'>
            <FeatureFence can={['freeze-logs']}>
              <NavBarItem to='/freeze-logs' className={className} icon={<FreezeLogsIcon style={{ fontSize: 24 }} />} label='Freeze Logs' />
            </FeatureFence>
          </PermissionFence>

          <PermissionFence can='read-sap-notification'>
            <NavBarItem to='/notifications' className={className} icon={<NotificationIcon style={{ fontSize: 24 }} />} label={pluralWorkTicketLabel} />
          </PermissionFence>

          <PermissionFence can='send-report'>
            <NavBarItem to='/report' className={className} icon={<ReportIcon style={{ fontSize: 24 }} />} label='Build Alarm Report' />
          </PermissionFence>
        </div>

      </div>
      <div id='navbar-lower' data-testid='navbar-lower' >
        <NavBarItem subnavbar icon={<HelpIcon style={{ fontSize: 24 }} />} label='Help' menuLabel="We're Here to Help!">
          <SubNavBarItem label='Contact Us' to='https://ehtgroup.com/schedule-a-call/' icon={<ContactIcon style={{ fontSize: 20 }} />} />
          {
            !whitelabel ? <SubNavBarItem label='Knowledge Base' to='https://app.ehtgroup.com/knowledge/' icon={<SupportIcon style={{ fontSize: 20 }} />} /> : null
          }
          {trainingURL && trainingPassword ? <SubNavBarItem label='Training' to='/training' icon={<LightbulbIcon style={{ fontSize: 20 }} />} /> : null}
          <div onClick={handleMobileDrawerItemClick}>
            <SubNavBarItem label='About' to='/about' icon={<InfoIcon style={{ fontSize: 20 }} />} />
          </div>
        </NavBarItem>

        {isMobile ? null : <NavBarItem className='collapse' onClick={toggleDrawer} icon={<MenuIcon style={{ fontSize: 24 }} />} label='Collapse' />}
      </div>
      <Popover
        open={openSettingsPopOver}
        anchorEl={anchorEl}
        onClose={handleCloseSettingsPopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="nav-popover-settings"
      >
        <div className='setting-popover-header'>
          <span>{clientName}</span>
        </div>
        <div onClick={handleMobileDrawerItemClick}>
          <PermissionFence can='edit-client'>
            <NavBarItem label='Facility' to='/settings/facility' onClose={handleCloseSettingsPopOver} icon={<FacilityIcon style={{ fontSize: 20 }} />} />
          </PermissionFence>
          <PermissionFence can='read-report-schedule'>
            <NavBarItem label='Maintenance Schedule' to='/settings/schedule' onClose={handleCloseSettingsPopOver} icon={<ScheduleIcon style={{ fontSize: 20 }} />} />
          </PermissionFence>
          <PermissionFence can='read-eht-alarm-rule'>
            <NavBarItem label='Criticality Rules' to='/settings/rules' onClose={handleCloseSettingsPopOver} icon={<RulesIcon style={{ fontSize: 20 }} />} />
          </PermissionFence>
          <PermissionFence can='edit-integration'>
            <NavBarItem label='Integrations' to='/settings/integrations' onClose={handleCloseSettingsPopOver} icon={<IntegrationsIcon style={{ fontSize: 20 }} />} />
          </PermissionFence>
          <PermissionFence can='edit-client'>
            <FeatureFence can={['configure-search']}>
              <NavBarItem label='Configure Search' to='/settings/configure-search' onClose={handleCloseSettingsPopOver} icon={<ConfigureSearchIcon style={{ fontSize: 20 }} />} />
            </FeatureFence>
          </PermissionFence>
          <NavBarItem label='Manage License' to='/settings/license-management' onClose={handleCloseSettingsPopOver} icon={<CardMembershipIcon style={{ fontSize: 20 }} />} />

          <Divider variant="middle" />

          <PermissionFence can='super-admin'>
            <div className='setting-popover-header'>
              <span>Super Administrator Menu</span>
            </div>

            <NavBarItem label='Alarm Groupings' to='/settings/alarm-simplification' onClose={handleCloseSettingsPopOver} icon={<AlarmsIcon style={{ fontSize: 20 }} />} />
            <NavBarItem label='Define Upgrade Path' to='/settings/upgrade-path' onClose={handleCloseSettingsPopOver} icon={<UpgradePathIcon style={{ fontSize: 20 }} />} />
            <Divider variant="middle" />
          </PermissionFence>

          <div className={classNames('setting-popover-header', 'user-settings')}>
            <div className='user-initals'>{user.get('first_name')?.toUpperCase()[0]}{user.get('last_name')?.toUpperCase()[0]}</div>
            <div className='user-details'>
              <div>{user.get('first_name')} {user.get('last_name')}</div>
              <div className="user-email">{user.get('email')}</div>
            </div>
          </div>
          <NavBarItem label='Profile' to='/profile' onClose={handleCloseSettingsPopOver} icon={<AccountCircleIcon style={{ fontSize: 20 }} />} />
        </div>
        {clients.size > 1 && (<NavBarItem label='Change Facility' to='#' isFlyOut onClick={handleClickOnChangeFacility} icon={<SwitchClientIcon style={{ fontSize: 20 }} />} />)}
        <PermissionFence can='smart-trace-admin'>
          <NavBarItem label='Administrator' to='/admin' onClose={handleCloseSettingsPopOver} icon={<AdminPanelSettingsIcon style={{ fontSize: 20 }} />} />
        </PermissionFence>
        <NavBarItem label='Sign Out' to='/logout' icon={<LogOutIcon style={{ fontSize: 20 }} />} />
      </Popover>
      {/* popover / flyout for change facility, triggeted by click on change facility */}
      <Popover
        disableRestoreFocus
        open={changeFacilityPopOver}
        anchorEl={anchorElForFacility}
        onClose={handleCloseChangeFacility}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="nav-popover-settings"
      >
        <ChangeClientModal clients={clients} currentClientId={currentClientId} handleClose={handleCloseChangeFacility} handleSubmit={handleChangeClient} />
      </Popover>
    </>
  );
}
SideNavBar.propTypes = {
  onToggleDrawer: PropTypes.func
};

SideNavBar.defaultProps = null;
