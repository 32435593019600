import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import LogoSpinner from './LogoSpinner';
import { fetchDeviceTags, clearDeviceTags } from 'src/module/device/action';
import { getDeviceTags, getLoadingDeviceTags } from 'src/module/device/selector';
import { formatCircuitTag } from 'src/utils/utils';

export default function DeviceSelectorField (props) {
  const {
    readonly,
    onChange,
    error,
    helperText,
    deviceId,
    required,
    type,
    hideLabel,
    value
  } = props;

  const dispatch = useDispatch();

  const deviceTags = useSelector(getDeviceTags);
  const loadingDeviceTags = useSelector(getLoadingDeviceTags);

  const labelLookup = {
    'circuit': 'Circuit',
    'controller': 'Controller',
    'circuitsAndControllers': 'Device'
  };
  const label = labelLookup[type] || 'Device';
  const [selectedOption, setSelectedOption] = useState(value ? value : { label: `Select a ${label}`, value: '' });
  const [options, setOptions] = useState([{ label: 'Select a Device', value: '' }]);
  const [_deviceId, setDeviceId] = useState(deviceId);

  const selectDevice = (e, _selectedOption) => {
    setSelectedOption(_selectedOption);
    if (_selectedOption) {
      onChange({ id: _selectedOption.value, tag: _selectedOption.label });
    } else {
      onChange('');
    }
  };

  useEffect(() => {
    if (!loadingDeviceTags) {
      const optionsArray = [];
      let _selectedOption = null;

      optionsArray.push({ label: `Select a ${label}`, value: '' });

      deviceTags.map((option) => {
        const optionId = option.get('id');
        const label = option.get('type') === 'circuit' ?
          formatCircuitTag(option.get('tag'), option.get('controller_tag')) :
          option.get('tag');

        const optionObj = { label, value: optionId, key: option.get('tag') };

        optionsArray.push(optionObj);

        if (optionId === _deviceId) {
          _selectedOption = optionObj;
        }
      });
      setOptions(optionsArray);
      if (_selectedOption) {
        setSelectedOption(_selectedOption);
      }
    }
  }, [loadingDeviceTags]);

  useEffect(() => {
    setDeviceId(deviceId);
    dispatch(clearDeviceTags());
    dispatch(fetchDeviceTags(null, type, null));
  }, [deviceId, value]);

  const filterOptions = (e) => {
    if (e.target.value && e.target.value.length >= 3) {
      // if the user enters a new circuit tag, we need to reset the device id - any id passed in by a prop would be invalid
      setDeviceId(null);
      dispatch(clearDeviceTags());
      dispatch(fetchDeviceTags(e.target.value, type));
    }
  };
  return (
    readonly ? <TextField error={error} helperText={helperText} value={selectedOption.label} label={label} data-testid='device-selector-field' />
      : <>
        <Autocomplete
          data-testid='device-selector-field'
          value={selectedOption}
          options={options}
          loading={loadingDeviceTags}
          isOptionEqualToValue={(option, value) => {
            return option && option.value === value.value;
          }}
          getOptionLabel={(option) => option['label'] || ''}
          onChange={selectDevice}
          renderInput={(params) => <TextField {...params} onChange={filterOptions} error={error} helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingDeviceTags && !hideLabel ? <LogoSpinner size={32} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            required={required}
            label={hideLabel ? '' : label}
          />}
        />
      </>
  );
}

DeviceSelectorField.propTypes = {
  deviceId: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.any,
  type: PropTypes.string,
  hideLabel: PropTypes.bool,
  value: PropTypes.object,
};

DeviceSelectorField.defaultProps = {
  deviceId: '',
  readonly: false,
  type: null,
  required: false,
  hideLabel: false,
  value: null
};
