import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import DeviceBreakDeviceList from './DeviceBreakDownList';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeviceRotate from 'src/component/UI/DeviceRotate';
import variables from 'src/style/variable/variables.module.scss';

export default function DeviceBreakDownContent (props) {
  const {
    title,
    deviceId,
    isExpanable,
  } = props;
  const [Collapsed, setCollapsed] = useState(false);


  const isMobile = useMediaQuery(`(max-width: ${variables.mobileWidth})`);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [showRotateMessage, setShowRotateMessage] = useState(isMobile && isPortrait);
  useEffect(() => {
    setShowRotateMessage(isMobile && isPortrait);
  }, [isMobile, isPortrait]);

  return (
    <Paper data-testid='alarms-content'>
      {isExpanable ?
        <h3 className='expandable' onClick={() => setCollapsed(!Collapsed)}>{title} &nbsp;<span className='expand-arrow'>{Collapsed ? '▲' : '▼'}</span></h3>
        :
        <h3>{title}</h3>
      }
      {!Collapsed && !showRotateMessage ?
        <DeviceBreakDeviceList
          deviceId={deviceId}
        />
        : ''}

      {Collapsed && showRotateMessage ?
        <DeviceRotate />
        : null}
    </Paper>
  );
}

DeviceBreakDownContent.propTypes = {
  title: PropTypes.string,
  deviceId: PropTypes.string,
  isExpanable: PropTypes.bool,
};
DeviceBreakDownContent.defaultProps = {
  title: String,
  isExpanable: false,
};
