import './SendModbusRequest.scss';
import '../../../component/UI/FooterBar.scss';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { FormControlLabel, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isInteger, isWhitelabel, unsignedToSigned16bit } from 'src/utils/utils';
import DeviceSelectorField from 'src/component/UI/DeviceSelectorField';
import { sendModbusRequest, sendModbusRequestClear } from 'src/module/device/action';
import { getModbusRequstData, getModbusRequstLoading } from 'src/module/device/selector';
import toast from 'src/utils/toast';
import Button from 'src/component/UI/Button';
import PropTypes from 'prop-types';

export default function SendModbusRequestScreen (props) {
  //props.match.params.device_tag
  const dispatch: any = useDispatch();
  const title = isWhitelabel() ? 'ATCOM' : 'SmartTrace';
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [functionCode, setFunctionCode] = useState('');
  const [address, setAddress] = useState('');
  const [length, setLength] = useState('');
  const [usedAddress, setUsedAddress] = useState('');
  const [unsigned, setUnsigned] = useState(true);
  const [format, setFormat] = useState('decimal');
  const [propsDeviceId, setPropsDeviceId] = useState(props.match.params.device_id);


  const maxColumns = 6;
  let minRowsPerColumn = 10;
  const results = useSelector(getModbusRequstData(selectedDevice?.id || propsDeviceId));

  const loading = useSelector(getModbusRequstLoading);

  const handleFormat = (event, newFormat) => {
    setFormat(newFormat);
  };

  const handleChange = (value) => {
    setPropsDeviceId(null);
    if ((value === null || value === '' || value.id === '')) {
      setSelectedDevice(null);
    } else {
      setSelectedDevice(value);
    }
  };
  const send = () => {
    if ((selectedDevice === null && !propsDeviceId)|| functionCode === '' || address === '' || length === '') {
      toast.error('Unable to send requests. Please fill in all fields.');
      return;
    }
    if (!isInteger(functionCode) || !isInteger(address) || !isInteger(length)) {
      toast.error('Unable to send requests. Please enter a number for function code, address, length');
      return;
    }
    const data = {
      deviceId: selectedDevice?.id || propsDeviceId,
      functionCode,
      address,
      length
    };
    setUsedAddress(address);
    dispatch(sendModbusRequest(data));
    dispatch(sendModbusRequestClear(selectedDevice?.id || propsDeviceId));
  };
  useEffect(() => {
    dispatch(sendModbusRequestClear(selectedDevice?.id || propsDeviceId));
  }, []);

  const formatValue = (val) => {
    if (format ==='decimal' && !unsigned) {
      return unsignedToSigned16bit(val);
    }
    if (format === 'hex') {
      return val.toString(16).padStart(4, '0').toUpperCase();
    }
    if (format ==='binary') {
      return <p className='binary-value'>{val.toString(2).padStart(16, '0')}</p>;
    }
    return val;
  };

  const renderTable = (rows, offSet, chunkSize) => (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Address</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((line, index) => <TableRow key={index}>
          <TableCell>{parseInt(index) + parseInt(usedAddress)+ (offSet*chunkSize)}</TableCell>
          <TableCell>{formatValue(line)}</TableCell>
        </TableRow>)}

      </TableBody>
    </Table>
  );

  const valueFormatOptions = () => (
    <ToggleButtonGroup
      value={format}
      exclusive
      onChange={handleFormat}
      aria-label="text alignment"
    >
      <ToggleButton value="decimal" aria-label="left aligned">
        decimal
      </ToggleButton>
      <ToggleButton value="hex" aria-label="centered">
        hex
      </ToggleButton>
      <ToggleButton value="binary" aria-label="right aligned">
        binary
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const chunkData = (arr, maxCols, minRows) => {
    const chunks = [];
    let remainingRows = [...arr];

    while (remainingRows.length > 0) {
      const rowsForColumn = remainingRows.slice(0, minRows);
      chunks.push(rowsForColumn);
      remainingRows = remainingRows.slice(minRows);

      // Stop if we've reached the max columns
      if (chunks.length === maxCols) break;
    }

    // If there are any remaining rows and we haven't hit max columns, distribute them
    if (remainingRows.length > 0 && chunks.length < maxCols) {
      const avgRowsPerColumn = Math.ceil(remainingRows.length / (maxCols - chunks.length));
      chunks.push(...Array.from({ length: maxCols - chunks.length }, (_, i) => remainingRows.slice(i * avgRowsPerColumn, (i + 1) * avgRowsPerColumn)));
    }

    return chunks;
  };

  const handleChangeUnsigned = (e) => {
    setUnsigned(e.target.checked);
  };


  if (results?.length > 60) {
    minRowsPerColumn = Math.ceil(results.length/6);
  }
  const dataChunks = results ? chunkData(results,  maxColumns, minRowsPerColumn) : [];
  return (
    <div className='page'>
      <Helmet>
        <title>{title} - Send Modbus Request</title>
      </Helmet>
      <Grid item xs={12}>
        <h2>Send Modbus Request</h2>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <div className='device-selector'>
                  <DeviceSelectorField onChange={handleChange} deviceId={propsDeviceId} hideLabel value={selectedDevice} type='circuitsAndControllers' />
                </div>
              </Grid>
              <Grid item xs={2}>
                <TextField id='fn' name='fn'
                  value={functionCode}
                  label='Function Code'
                  onChange={(e) => setFunctionCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField id='address' name='address'
                  value={address}
                  label='Address'
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField id='length' name='length'
                  value={length}
                  label='length'
                  onChange={(e) => setLength(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button loading={loading} backgroundColor="primary" className='modbus-send-button' color="white" onClick={send}>
                  Send
                </Button>
              </Grid>


            </Grid>

          </Paper>

        </Grid>

        {results &&
          <Grid item xs={12}>
            <Paper className='response-paper'>
              <h1 className='response-title'>Modbus Response </h1>
              <div className='format-selector'>
                {format==='decimal' && <FormControlLabel control={<Switch checked={unsigned} onChange={(e) => handleChangeUnsigned(e)} />} label="Unsigned" />}
                {valueFormatOptions()}
              </div>

              <Grid container spacing={4}>
                {dataChunks.map((chunk, index) => (
                  <Grid item xs={2} key={index}>
                    {renderTable(chunk, index, dataChunks[0].length)}

                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        }

      </Grid>
    </div>
  );
}
SendModbusRequestScreen.propTypes = {
  match: PropTypes.object
};

SendModbusRequestScreen.defaultProps = {};
